/* USER EDIT */

/* Import default styles */
@import "../../style";


// Remove tabs for all user pages
nav.tabs{ display: none; }


/**
*   Page user register account
*/
body.page-user-register{
    #block-cepheid-content {
        background-color: rgba(245, 246, 245, 0.2);
        max-width: 1320px;
        margin: 40px 0;
        padding: 40px 70px;
        
        form#user-register-form {
            h2 {
                font-size: 2.1875rem;
                padding-bottom: 0;
            }
            h3 {
                font-size: 1.5rem;
                padding: 0 0 20px 0;
            }
            .informations, #password, #edit-field-email-representative-wrapper, #edit-field-system-serial-number-wrapper {
                h3 {
                    padding-left: 30px;
                    font-size: 20px;
                    position: relative;
                    color: $black;
                    margin-bottom: 20px;
                    
                    
                    &:before {
                        font-family: 'icon' !important;
                        font-size: 45px;
                        position: absolute;
                        left: -30px;
                        top: -20px;
                    }
                    
                }
                
                label {
                    color: $blue;
                    font-size: 13px;
                    margin-bottom: 0;
                }
                input {
                    margin-top: 0;
                    color: $grey-dark;
                    border-bottom: 2px solid $grey-dark !important;
                    //font-family: $h;
                    font-weight:400;
                    font-family: $font-regular;
                    padding-left: 0;
                }
                
                input[type="text"]:focus {
                    outline:none !important;
                }
            }
            .informations {
                
                h3 {
                    @extend .icon-green-profile;
                }
                
                div[data-drupal-selector="edit-group-column-1"], div[data-drupal-selector="edit-group-column-2"] {
                    @include media-breakpoint-down(sm) {
                        display: flex;
                        flex-direction: column;
                    }    
                }
                
                #edit-field-last-name-wrapper, #edit-field-company-wrapper, #edit-field-first-name-wrapper, #edit-field-country-wrapper {
                    width: 50%;
                    
                    @include media-breakpoint-down(sm) {
                        width: 100%;
                        padding-right: 0;
                        padding-left: 0;
                    }
                }
                
                input {
                    max-width: 450px;
                }
                
                
            }
            
            #password {
                margin-bottom: 0 !important;
                h3 {
                    @extend .icon-green-profile;
                }
                #edit-pass {
                    display: flex;
                    justify-content: space-between;
                    flex-direction: column;
                    @include media-breakpoint-down(sm) {
                        flex-direction: column;
                    }
                    
                    #edit-pass--description {
                        display: none;
                    }
                }
                
                #edit-pass > fieldset { 
                    width: 50%; 
                    float: left;
                    padding: 0;
                    
                    @include media-breakpoint-down(sm) {
                        width: 100%;
                        padding-right: 0;
                        padding-left: 0;
                    }
                    
                    &:first-child {
                        padding-left: 0 !important;
                    }
                    
                    
                    .password-confirm-message {
                        display: none;
                    }
                }
                
                #edit-account {
                    @include media-breakpoint-down(sm) {
                        padding-right: 0;
                        padding-left: 0;
                    }
                    
                    #edit-mail--description {
                        display: none;
                    }
                    
                    fieldset {
                        #edit-current-pass--description {
                            display: none;
                        }
                    }
                }
            }
            div.js-form-type-checkbox {
                margin: 80px auto;
                border-top: 1px solid grey;
                border-bottom: 1px solid grey;
                padding: 30px 0;
                width: 95%;
            }
            .captcha {
                margin: 0 auto 20px auto;
                width: 300px;
            }

            #edit-actions {
                // background:rgba(236, 237, 236, .25);
                height: 90px;
                display: flex;
                justify-content: center;
                
                #edit-submit {
                    align-self: center;
                    width: 220px;
                    min-height: 50px;
                }
            }

        }
    }
}

/**
*   Page de mise à jour de son mot de passe
*/
body.page-user-reset.title-reset-password{
    // Hide tac
    #tarteaucitronRoot{
        display: none !important;
    }
}

html {
    overflow-x: hidden;   
}


/**
*   Page de Login (et 404/403)
*/
body.title-log-in,
body.page-user-login{ 
    background:url(../images/loginbg.png) no-repeat center center fixed;
    background-size: cover;
    
    @include media-breakpoint-down(sm) {
        background-image: none;
        #main-wrapper{
            padding-right: 0 !important;
            padding-left: 0 !important;
            #content{
                padding-right: 0;
                padding-left: 0;
                max-width: 100% !important;
            }
        }
    }
    
    #page-wrapper #main-wrapper #content {
        background-color: transparent;
    }
    #header{ 
        display: none; 
    }
    #block-cepheid-content{
        @include media-breakpoint-up(md) { padding-top: 25px; }
        #user-login-form{
            @extend .col-lg-6, .offset-lg-6, .col-md-8, .offset-md-4;
            background:$white;
            box-shadow: 0px 3px 25px #22222280;
            border-radius: 5px;
            padding: 30px 80px;
            
            // logo
            & > .text-center > img{
                margin: 10px 0 40px 0;
            }
            h2{
                //font-family: $h !important; 
                font-weight: 700;
                font-family: $font-bold !important;
                margin: 0;
            }
            & > p{
                &:nth-child(4){ 
                    //font-family: $h; 
                    font-weight:500;
                    font-family: $font-semibold;
                }
                a{
                    padding-left:10px;
                }
            }
            
            .form-item-accept{
                width: 175px;
                margin: 0 auto;
                padding: 0;
            }
            .form-actions{ 
                text-align: center;
            }
            label.form-required{
                color:$blue !important;
                @include font-size(12);
            }
            fieldset{
                padding: 0;
            }
            #edit-name--description, #edit-pass--description{ display: none; }
            
            // button submit
            #edit-submit{
                width: 150px;
                margin: 20px auto;
                height: 45px;
            }
            a[href="/user/password"]{
                @include font-size(13);
                //font-family: $h; 
                font-weight:500;
                font-family: $font-semibold;
            }
            
            @include media-breakpoint-down(sm) {
                margin: 0;
                padding: 0;
                box-shadow: none;
                & > h2{
                    padding-top:20px;
                    padding-right:15px;
                    padding-left:15px;
                }
                & > *{
                    margin-right:15px;
                    margin-left:15px;
                }
                // logo small for mobile
                & > .text-center{
                    background-color: $blue;
                    margin:0 !important;
                    & > img{
                        margin: 20px auto !important;
                        max-width: 170px;
                    }
                }
                
            }
        }
        & > div > .item-list{
            display: none;
        }
    }
}

/**
*   Page : Managed saved search
*/
.saved-search-wrapper{
    & > h2{
        @include font-size(35);
        margin: 20px 0;
    }
    ul.all-saved-search {
        
        li.item {
            padding: 20px 20px;
            position: relative;
            border: none;
            background-color: rgba($grey-extralight, 0.25);
            .title {
                //font-family: $h; 
                font-weight:500;
                font-family: $font-semibold;
                font-size: 20px;
                margin-bottom: 20px;
                max-width: 80%;
            }
            .wrapper {
                display: flex;
                flex-wrap: wrap;
                padding-right: 100px;
                
                @include media-breakpoint-down(md) {
                    justify-content: flex-start;
                }
                
                .input, .produit, .start, .end, .type, .language {
                    width: 100px;
                }
                
                .input, .produit, .type, .language, .start, .end, .actions {
                    margin-right: 20px;
                    margin-bottom: 20px;
                    
                    label {
                        font-size: 12px;
                        //font-family: $h; 
                        font-weight:400;
                        font-family: $font-regular;
                    }
                    span {
                        font-size: 16px;
                        //font-family: $h; 
                        font-weight:400;
                        font-family: $font-regular;
                    }
                }
                
                .actions {
                    position: absolute;
                    right: 20px;
                    top: 30px;
                    margin-right: 0;
                    width: 70px;
                    
                    @include media-breakpoint-down(lg) {
                        /*width: 100%;*/
                    }
                    
                    .actions-group, .actions-search {
                        a {
                            font-family: 'icon' !important;
                            
                            &:hover {
                                text-decoration: none;
                            }
                        }
                    }
                    
                    .actions-group {
                        display: flex;
                        justify-content: space-around;
                        margin-bottom: 20px;
                        
                        a.edit {
                            @extend .icon-edit;
                            font-size: 17px;
                        }
                        a.delete {
                            @extend .icon-delete;
                            font-size: 17px;
                        }
                    }
                    .actions-search {
                        padding-right: 4px;
                        display: flex;
                        justify-content: flex-end;
                        
                        a.search {
                            @extend .icon-blue-search;
                            font-size: 25px;
                            display: block;
                        }
                    }
                }
            }
        }
    }
}



/**
*       User edit form
*/

form#user-form{
    & > h2{
        @include font-size(35);
        margin: 20px 0;
    }
    & > div:not(#edit-actions){
        background:rgba(236, 237, 236, .25);
        margin-bottom: 10px;
        padding: 30px 80px 10px 80px;
        
        @include media-breakpoint-down(md) {
            padding: 30px 50px 10px 50px;
        }
    }
    
    div.info{
        padding:0 0 15px 15px;
    } 
    
    .subscribe, .informations, #password, #edit-field-email-representative-wrapper, #edit-field-system-serial-number-wrapper {
        h3 {
            padding-left: 30px;
            font-size: 20px;
            position: relative;
            color: $black;
            margin-bottom: 20px;
            
            
            &:before {
                font-family: 'icon' !important;
                font-size: 45px;
                position: absolute;
                left: -30px;
                top: -20px;
            }
            
        }
        
        label {
            color: $blue;
            font-size: 13px;
            margin-bottom: 0;
        }
        input {
            margin-top: 0;
            color: $grey-dark;
            border-bottom: 2px solid $grey-dark !important;
            //font-family: $h; 
            font-weight:400;
            font-family: $font-regular;
            padding-left: 0;
            &.select2-search__field{
                border-bottom:none !important;
            }
        }
        
        input[type="text"]:focus {
            outline:none !important;
        }
    }

    .js-form-item-field-subscribe {
        .select2-selection__clear{
            display: none;
        }
    }
    
    .subscribe {
        //margin-top: 150px; 
        h3 {
            @extend .icon-green-subscribe;
        }
        div.info {
            padding: 0 0 15px 30px;
        }
        
        #edit-field-subscribe-wrapper {
            @include media-breakpoint-down(sm) {
                padding-right: 0;
                padding-left: 0;
            }
            
            fieldset {
                span.select2 {
                    width: 100% !important;
                }
                input.select2-search__field{
                    border-bottom:none !important;
                }
            }
        }
    }   
    .informations {
        
        h3 {
            @extend .icon-green-profile;
        }
        
        div[data-drupal-selector="edit-group-column-1"], div[data-drupal-selector="edit-group-column-2"] {
            @include media-breakpoint-down(sm) {
                display: flex;
                flex-direction: column;
            }    
        }

        div[data-drupal-selector="edit-group-column-2"] {
            #edit-field-email-representative-wrapper, #edit-field-system-serial-number-wrapper {
                display: none !important;
            }
        }
        
        #edit-field-last-name-wrapper, #edit-field-company-wrapper, #edit-field-first-name-wrapper, #edit-field-country-wrapper {
            width: 50%;
            
            @include media-breakpoint-down(sm) {
                width: 100%;
                padding-right: 0;
                padding-left: 0;
            }
        }
        
        input {
            max-width: 450px;
        }
        
        
    }
    #password {
        margin-bottom: 0 !important;
        h3 {
            @extend .icon-green-profile;
        }
        #edit-pass {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            @include media-breakpoint-down(sm) {
                flex-direction: column;
            }
            
            #edit-pass--description {
                display: none;
            }
        }
        
        #edit-pass > fieldset { 
            width: 50%; 
            float: left;
            padding: 0;
            
            @include media-breakpoint-down(sm) {
                width: 100%;
                padding-right: 0;
                padding-left: 0;
            }
            
            &:first-child {
                padding-left: 0 !important;
            }
            
            
            .password-confirm-message {
                display: none;
            }
        }
        
        #edit-account {
            /*position: relative;
            margin-top: 50px;*/
            
            /*&:before {
                content:"";
                position: absolute;
                width: 100%;
                height: 1px;
                right: 0px;
                top: -30px;
                background: rgba(112, 112, 112, 0.25);
            }*/
            
            @include media-breakpoint-down(sm) {
                padding-right: 0;
                padding-left: 0;
            }
            
            #edit-mail--description {
                display: none;
            }
            
            fieldset {
                #edit-current-pass--description {
                    display: none;
                }
            }
        }
    }
    
    #edit-actions {
        background:rgba(236, 237, 236, .25);
        height: 90px;
        display: flex;
        justify-content: center;
        
        #edit-submit {
            align-self: center;
            width: 220px;
            min-height: 50px;
        }
    }
}

// Custom select2 results
#select2-edit-field-subscribe-results{
    .select2-results__option {
        padding: 0px 6px;
        & > span{
            padding: 6px 0;
            display: inline-block;
        }
        .arrow{
            left: -7px;
            position:relative;
            width: 10px;
            display: inline-block;
            height: 10px;
            &:before{
                content:""; 
                border-color: #888 transparent transparent transparent;
                border-style: solid;
                border-width: 5px 4px 0 4px;
                height: 0;
                left: 5px;
                margin-left: -4px;
                margin-top: -2px;
                position: absolute;
                top: 50%;
                width: 0;
            }
        }
        .level1{
            color:$black;
            padding-left:10px;
        }
        &.open{
            .level1 .arrow{
                &:before{
                    transform: rotate(180deg);
                }
            }
        }
        .level2{
            display:none;
            padding-left:20px;
        }
        .level3{
            display:none;
            padding-left:25px;
        }
        .level4{
            display:none;
            padding-left:30px;
        }
        
    }
}


/**
*       User reset password page
*/
body.page-user-password{
    // Remove header tabs (to default login page)
    nav.tabs{ display: none; }
    form.user-pass{
        background-color: rgba($grey-extralight, 0.25);
        max-width:980px;
        margin: 40px 0;
        padding: 40px 70px;
        input.btn{
            width: auto;
            margin: 0 auto;
            padding: 10px 40px;
        }
    }
}

form#user-pass-reset{
    margin: 50px auto;
    max-width: 80%;
    #edit-actions{
        margin: 0;
        padding: 0;
        #edit-submit{
            display: inline-block;
            width: auto;
            min-width: 160px;
        }
    }
}

/**
*       User saved search page
*/
.all-saved-search{
    list-style: none;
    padding:0;
    .item{
        background:#f1f1f1;
        border:1px solid #ccc;
        margin: 10px 0;
        padding: 10px 20px;
        .title{
            color:$black;
            @include font-size(20);
        }
        .wrapper{
            display: flex;
            justify-content: space-between;
            & > div{
                label{
                    color:$blue;
                    display:block;
                    @include font-size(12);
                }
                &.actions{
                    
                }
            }
        }
    }
}

form#user-register-form{
    .form-check-label{
        cursor: pointer;
    }
    .custom_checkbox:not(:checked) + label:before,
    .custom_checkbox:checked + label:before {
        top: 2em;
    }

    /* Aspect de la coche */
    .custom_checkbox:not(:checked) + label:after,
    .custom_checkbox:checked + label:after {
        top: 2.45em;
    }
}
